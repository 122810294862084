import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SocialIcon from '../components/social-icon';
import './index.scss';

const IndexPage = () => {
  return (
    <Layout className="mcd-home">
      <SEO title="Brian McDaniel" />
      <h1>Brian McDaniel</h1>
      <ul className="social-list">
        <li className="icon-list-item">
          <SocialIcon
            img={require('../images/github-icon.png')}
            url="https://github.com/brianmcd"
            handle="@brianmcd"
          />
        </li>
        <li className="icon-list-item">
          <SocialIcon
            img={require('../images/twitter-icon.svg')}
            url="https://twitter.com/brian_mcdaniel"
            handle="@brian_mcdaniel"
          />
        </li>
        <li className="icon-list-item">
          <SocialIcon
            img={require('../images/linkedin-icon.png')}
            url="https://www.linkedin.com/in/brian-mcdaniel-65887048"
            handle="Brian McDaniel"
          />
        </li>
      </ul>
      <hr />
    </Layout>
  );
};

export default IndexPage;
