import React from 'react';
import './social-icon.scss';

interface Props {
  img: string;
  url: string;
  handle: string;
}

const SocialIcon = ({ img, url, handle }: Props) => {
  return (
    <div className="mcd-social-icon">
      <a href={url} target="_blank">
        <img src={img} width="20" height="20" />
        {handle}
      </a>
    </div>
  );
};

export default SocialIcon;
